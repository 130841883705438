// HomePage.js
import React, { useState,useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import '../../css/termite.scss'
import video from '../../image/fireant/Fire_ants_detection.mp4'


const FireAnt = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const [tipsText,setTipsText] = useState ([{
        title:'High-Resolution Images:',
        content:'For optimal clarity and zooming capabilities, upload high-resolution images.'
        },{
        title:'Supported Formats:',
        content:'Ensure your images are in commonly supported formats such as JPEG, PNG, or TIFF.'
        },{
        title:'Proper Lighting and Focus:',
        content:'Capture images in well-lit conditions with a focus on the subject to enhance clarity.'
        },{
        title:'Avoid Compression:',
        content:'Minimize the use of image compression to preserve image quality.'
        }
        ])

        const [hover,setHover]= useState(false)

        const handleHoverCover = () => {
        setHover(true)  
        };

        const handleHoverCoverDone = () =>{
        setHover(false)
    }
  return (
    <div className="page">
    <Header />
    <div className="mainBody">
      <div className="imageSection">
        <div style={{ textAlign: 'center'}}>
          <h1 className='bigTitle'>Fire Ant Detection</h1>
          {/* <ImageSection /> */}
        </div>
      </div>
      <div className="videoDisplay">
        <video max-width="1240" height="660" controls autoPlay>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </div>
      <div className="fireantPics">
         {Array(6).fill(6).map((el, i) =>
            <img src={require(`../../image/fireant/p${i+1}.jpg`)} alt="logo" className=''/>
         )}
        </div>
        {/* <div>
          <video width="640" height="360" controls autoPlay>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </div> */}
      {/* <div className="detailAera">
        <div className='tipsArea'>
          <h2 className="title">
            Tips for uploading Images
          </h2>
          <h4 className="tipsubtitle">
            To ensure the best outcome, we encourage to upload:
          </h4>
          <div className="tipBlocks">
            {tipsText.map((item,i)=>(
              <div className="tipBlock" key={i}>
                <h2 className="tipIndex">{i+1}</h2>
                <div className="tips">
                  <h5 className="tipTitle" >{item.title}</h5>
                  <h6>{item.content}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
    <Footer />
    {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
  </div>

    
  );
};

export default FireAnt;
