import { Heading } from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';

const HeadingWithId = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        default: null,
        parseHTML: (element) => element.id,
        renderHTML: (attributes) => {
          if (!attributes.id) return {};
          return { id: attributes.id };
        },
      },
    };
  },
});

const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
        parseHTML: element => element.style.width || null,
        renderHTML: attributes => {
          // if (!attributes.width) return {};
          return { style: `width: 50%` };
        },
      },
      height: {
        default: null,
        parseHTML: element => element.style.height || null,
        renderHTML: attributes => {
          // if (!attributes.height) return {};
          return { style: `height: 370px` };
        },
      },
    };
  },
});

export {HeadingWithId,CustomImage};
