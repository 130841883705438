// import axios from 'axios'
// const URL = 'http://127.0.0.1:5000/api/';
const URL = 'https://aipd.pro/api/'; // production

const apiRequest = async (type, method, request, optional) => {
    // const token = localStorage.getItem('token');
    // const id=localStorage.getItem('uId');
    // let myHeaders = new Headers({
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'text/plain'
    // });

    let response;
    switch (type) {
        case 'upload': {
            const options = {
                // headers: {
                //     'Content-type': 'application/json',
                //     'Access-Control-Allow-Origin': '*',
                // },
                // method,
                // mode:'cors',
                // body: JSON.stringify({
                //     request
                // }),
                method: "POST",
                body: request,
            }
            response = await fetch(URL+"upload",options)
            break;
        }
        case 'editToolPics': {
            const options = {
                method: "POST",
                body: request,
            }
            response = await fetch(URL+"editToolPics",options)
            break;
        }
        case 'generatePDF': {
            const options = {
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                method,
                mode:'cors',
                body: JSON.stringify({
                    request
                }),
                method: "POST",
                body: request,
            }
            response = await fetch(URL+"generatePDF",options)
            break;
        }
        default:{
            console.log('error')
        }

    }
    return response;
}

export default apiRequest;
