// Footer.js
import React from 'react';
import "../css/foot.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate(); 
  const goContact = async () => {
    navigate("/ContactUs");
  }
  const goUs = async () => {
    navigate("/AboutUs");
  }
  const goHome = async () => {
    navigate("/");
  }
  return (
    <footer className="footercover">
      <div className='footerLeft'>
        <div>
          <h4>AIPD</h4>
          <h5>Empowering Tomorrow with AI</h5>
        </div>
        <h5>Innovation, Collaboration, Integrity © 2024</h5>
      </div>
      <div>
        <h5 className='footerTitle'>Help</h5>
        <h6 className='footerclick' onClick={goHome}>Homepage</h6>
        <h6 className='footerclick' onClick={goUs}>About Us</h6>
        <h6 className='footerclick' onClick={goContact}>Contact Us</h6>
      </div>
    </footer>
  );
};

export default Footer;
