import React from 'react';
import '../css/Modal.css'; // Create this CSS file for styling
import Beam from "../components/simplySupportedBeam";


function Modal({ isOpen, onClose}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>

        <Beam />
        <button className="modal-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default Modal;
