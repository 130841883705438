import Lenis from 'lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
});

function raf(time) {
    lenis.raf(time);
    ScrollTrigger.update();
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

export const initializeAnimations = () => {
    // section1
    const part1 = document.querySelector('.part1');
    const timein = gsap.timeline();
    timein.fromTo(part1, { opacity: 0, y: '5vh' }, { opacity: 1, duration: 1, y: '0' });

    // section2
    const part2 = document.querySelector('.part2');
    const section1_gone_time = gsap.timeline();
    section1_gone_time.fromTo(part1, { opacity: 1 }, { opacity: 0, duration: 1, y: '-5vh' });

    const section1_gone_set = ScrollTrigger.create({
        animation: section1_gone_time,
        trigger: part2,
        start: 'top bottom',
        end: 'bottom',
        scrub: true,
        // markers: true,
    });

    const products_items = gsap.utils.toArray('.products');
    products_items.forEach((box, index) => {
        gsap.fromTo(box, 
            { opacity: 0,x:Math.pow(-1, index+1)*1000, }, // 起始状态
            { 
                x: 0, 
                opacity: 1, 
                duration: 1, 
                scrollTrigger: {
                    trigger: box,
                    start: 'top 90%',
                    end: 'top 60%',
                    scrub: true,
                    // markers: true,
                }
            });
    });

    // section3
    const part3 = document.querySelector('.part3');
    const part3_time = gsap.timeline();
    part3_time.fromTo(part3, { opacity: 0, y: '30vh' }, { opacity: 1, duration: 1, y: '0' });

    const section_3_create = ScrollTrigger.create({
        animation: part3_time,
        trigger: part2,
        start: 'bottom bottom',
        end: 'bottom',
        scrub: 1,
        // markers: true,
    });

    return () => {
        // Cleanup function to remove all ScrollTriggers and animations
        timein.kill();
        section1_gone_time.kill();
        part3_time.kill();
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
};