import React, { useEffect, useState } from 'react';
import '../css/beam.css'; // Import the CSS file


const Beam = () => {
  const [beamLength, setBeamLength] = useState(1);
  const [pointLoads, setPointLoads] = useState([
    { value: -1, position: 50 },
  ]);
  const [lineLoad, setLineLoad] = useState(-1);
  const [beamData, setBeamData] = useState({})
  const [imageStructure, setImageStructure] = useState(''); // State to hold the base64 image
  const [imageBending, setImageBending] = useState(''); // State to hold the base64 image
  const [imageShear, setImageShear] = useState(''); // State to hold the base64 image
  const [imageReaction, setImageReaction] = useState(''); // State to hold the base64 image


  const add_point_load = () => {
    setPointLoads((pointLoads) => [...pointLoads, { value: -1, position: 95 }]);
  };

  const handlePointLoadChange = (index, field, value) => {
    const newPointLoads = [...pointLoads];
    newPointLoads[index][field] = value;
    setPointLoads(newPointLoads);
  };

  const delete_point_load = (indexToRemove) => {
    setPointLoads((pointLoads) =>
      pointLoads.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(
    () => {setBeamData({
      "l": beamLength,
      "q": lineLoad,
      "p": pointLoads
    })}
  , [beamLength, pointLoads, lineLoad])

  function normalizeData(data) {
    return {
      ...data,
      l: Number(data.l), // Convert 'l' to a number if needed
      q: Number(data.q), // 'q' is a string, so no conversion needed
      p: data.p.map(item => ({
        value: Number(item.value), // Ensure 'value' is a number
        position: Number(item.position)/100 // Ensure 'position' is a number
      }))
    };
  }
  

  const handleCalculate = () => {

    fetch('http://127.0.0.1:5000/calculate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(normalizeData(beamData)),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:');
        setImageStructure(result.images.structure)
        setImageBending(result.images.bending_moment)
        setImageShear(result.images.shear_force)
        setImageReaction(result.images.reaction_force)
        // Handle the result here (e.g., display the result to the user)
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle errors here
      });
  };


  return (
    <div className="container">
      <div className="beam-properties">
        <h2>Simply Supported Beam</h2>
        <div>
          <label>
            Length of Beam (L) (m):
            <input
              type="number"
              value={beamLength}
              onChange={(e) => setBeamLength(e.target.value)}
            />
          </label>
        </div>
        <h3>Position of Point Loads from Left</h3>
        {pointLoads.map((pointLoad, index) => (
          <div className="point-loads" key={index}>
            <label>
              Value (kN):
              <input
                type="number"
                value={pointLoad.value}
                onChange={(e) =>
                  handlePointLoadChange(index, 'value', e.target.value)
                }
              />
            </label>
            <label>
              Position (%):
              <input
                type="number"
                value={pointLoad.position}
                onChange={(e) =>
                  handlePointLoadChange(index, 'position', e.target.value)
                }
              />
            </label>
            <button
              className="delete-button"
              onClick={() => delete_point_load(index)}
            >
              ✖
            </button>
          </div>
        ))}
        <button className="function-button" onClick={add_point_load}>Add Point Load</button>
        <h3>Line Loads</h3>
        <div>
          <label>
            Value of the Line load (q) (kN/m):
            <input
              type="number"
              value={lineLoad}
              onChange={(e) => setLineLoad(e.target.value)}
            />
          </label>
        </div>
        <button className="function-button" onClick={handleCalculate}>
          Calculate
        </button>
      </div>
      <div className="beam-image">
        {imageStructure && (
        <img
          src={`data:image/png;base64,${imageStructure}`}
          alt="Beam Structure"
        />
        )}
      </div>
      <div className="beam-image">
        {imageBending && (
        <img
          src={`data:image/png;base64,${imageBending}`}
          alt="Beam Bending"
        />
        )}
      </div>
      <div className="beam-image">
        {imageShear && (
        <img
          src={`data:image/png;base64,${imageShear}`}
          alt="Beam Shear"
        />
        )}
      </div>
      <div className="beam-image">
        {imageReaction && (
        <img
          src={`data:image/png;base64,${imageReaction}`}
          alt="Beam Reaction"
        />
        )}
      </div>

    </div>
  );
};

export default Beam;
