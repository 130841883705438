import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TermitePage from './page/products/termite.jsx';
import FireAnts from './page/products/fireant.jsx'
import Home from './page/home.jsx';
// import TermitePage from './page/termitePage.jsx';
import Report from './page/report.jsx';
import ContactUs from './page/contactUs.jsx';
import AboutUs from './page/aboutUs.jsx';
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import "./css/common.scss"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ScrollToTop from './js/scrollTop.js';

const theme = createTheme({
  palette: {
    primary : {
      main: '#8531DFFF',
      light: '#602F95FF',
      dark: '#602F95FF',
      contrastText: '#E5E6EB',
    },
    background:{
      default: "#e4f0e2"
    }
  },
});


const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop/>
        <Routes> 
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/FireAnt"
            element={<FireAnts />}
          />
          <Route
            path="/TermitePage"
            element={<TermitePage />}
          />
          <Route
            path="/Report"
            element={<Report />}
          />
          <Route
            path="/ContactUs"
            element={<ContactUs />}
          />
          <Route
            path="/AboutUs"
            element={<AboutUs />}
          />
          
        </Routes>
      </Router>
    </ThemeProvider>
  );

};

export default App;
