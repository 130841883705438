// HomePage.js
import React, { useState,useEffect } from "react";
import Header from "../components/header";
import ImageSection from "../components/imageSection";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import background1 from '../image/background.png'
import '../css/home.scss'
import Button from '@mui/material/Button';
import {initializeAnimations} from '../js/home.js'
import computeVision from '../image/cv.png';
import dataAnalysis from '../image/dataa.png'
import reportWriting from '../image/reportWriting.png'
import chatbot from '../image/chatbot.png'
import AboutUs from '../components/aboutUs.jsx'

const Home = () => {
    const navigate = useNavigate(); 
    const handlecontactUs =()=>{
        navigate("/ContactUs");
    }
    const GoComputeVision =() =>{
        navigate("/Fireant");
    }
    const GoReport = () => {
        navigate("/Report");
    }

    useEffect(() => {
        const cleanupAnimations = initializeAnimations();
    
        return () => {
          cleanupAnimations();
        };
      }, []);

  return (
    <div className="page">
      <Header />
      <div className="mainBody">
        <div className="part1" id="part1">
            <div className="text">
                <h1>Empower  <br/>Civil Engineering <br/> with AI</h1>
                <Button variant="contained" onClick={handlecontactUs}>
                    Contact Us!
                </Button>
            </div>
            <img src={background1} className="mainbackground"/>
        </div>
        <div className="part2" id="part2">
            <div className="container">
                <div className="products" onClick={GoComputeVision}>
                    <img src={computeVision} />
                    <div className="textarea">
                        <div>
                            <h2>Computer Version</h2>
                            <Button variant="contained">Learn More</Button>
                        </div>
                    </div>
                </div>
                <div className="products">
                    <div className="textarea">
                        <h2>Chatbot</h2>
                    </div>
                    <img src={chatbot} />
                </div>
                <div className="products">
                    <img src={dataAnalysis} />
                    <div className="textarea">
                        <h2>Data Analysis</h2>
                    </div>
                </div>
                <div className="products" onClick={GoReport}>
                    <div className="textarea">
                        <div>
                            <h2>Report Writing</h2>
                            <Button variant="contained">Learn More</Button>
                        </div>
                    </div>
                    <img src={reportWriting} />

                </div>
            </div>
        </div>
      </div>
      <div className="part3" id="part3">
        <AboutUs/>
      </div>
      <Footer />
      {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
    </div>
  );
};

export default Home;
