// HomePage.js
import React, { useState,useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import '../css/contactus.scss'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import emailjs from "@emailjs/browser";

import logo from '../svg/logo.svg';

import CircularProgress from '@mui/material/CircularProgress';

import success from '../svg/success.gif';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const HomePage = () => {
  const [message,setMessage]=useState({
    'name':'',
    'email':'',
    'subject':'',
    'message':'',
  });
  const [messageResponse,setMessageResponse]=useState(0)
  const [loading,setLoading]=useState(0)
  const [error,setError]=useState(0)
  const [allMessage,setAllMessage]=useState(1)
  const [errorAlert,seterrorAlert] = useState(false)

  const checkDetails = () => {
    if(!(message.name!=''&&message.email!=''&&message.subject!=''&&message.message!='')){
      return false
    }
  }

  useEffect(() => {
    if(!(message.name!=''&&message.email!=''&&message.subject!=''&&message.message!='')){
      setAllMessage(1)
    }else{
      setAllMessage(0)
    }
  }, [message]);
    
  const sendEmail = async () => {
    if(allMessage==1){
      seterrorAlert(true)
    }else{
      setLoading(1)
      emailjs.send('service_33con6k', 'template_l4q29nl', message, 'TrMdOIjPXnvIDjGW-')
      .then((response)=> {
        setLoading(0)
        setMessageResponse(1)
      })
      .catch((error)=>{
        setLoading(0)
        setError(1)
        alert(error)
      });
    }
  }

  const errorAlertClose = () =>{
    seterrorAlert(false)
  }

  const getName = (val,key) => {
    setMessage((prevState) => ({
      ...prevState,
      [key]: val.target.value,
    }));
  }

  return (
    <div className="page">
      <Header />
      <div className="contact mainBody">
        <div className="contactUs">
        
            <h1>Contact Us</h1>
            <div className="subTitle">Feel free to reach out to us to address any inquiries or report any issues. We're here to assist you!</div>
            <div className="contactForm">
                {messageResponse==0?
                  <Card sx={{ maxWidth: 475 }} className="cardbackground">
                      <CardContent>
                      <div>
                            <TextField
                            className="textField"
                                required
                                id="margin-dense" margin="dense"
                                label="Name or Company Name"
                                type="text"
                                onChange={val=>getName(val,'name')}
                                />
                            <TextField
                            className="textField"
                                required
                                id="margin-dense" margin="dense"
                                label="Email Address"
                                type="email"
                                onChange={val=>getName(val,'email')}
                                />
                            <TextField
                                required
                                className="textField"
                                id="margin-dense" margin="dense"
                                label="Subject"
                                type="text"
                                onChange={val=>getName(val,'subject')}
                                />
                            <TextField
                              className="textField"
                              required
                                id="margin-dense" margin="dense"
                                label="Message"
                                multiline
                                onChange={val=>getName(val,'message')}
                                rows={4}
                                />
                      </div>
                      </CardContent>
                      <CardActions>
                        <div className="sendbtn">
                            <Button variant="contained" onClick={sendEmail}>Contact Us !</Button>
                        </div>
                      </CardActions>
                  </Card>
                : 
                <Card sx={{ maxWidth: 500 }} className="cardbackground">
                  <CardContent>
                    <div className="responseText">
                      Thank you, {message.name},
                      <br/><br/>
                      We will be in touch with you as soon as possible. 
                      <br/><br/>
                      Your inquiry is important to us, 
                      <br/>and we appreciate your patience.
                    </div>
                    <img className="icon" src={success} alt="Successful!" /> 
                  </CardContent>
                </Card>
                }
            </div>
            <div>
              {loading==1?
                <div className='loadingPage'>
                    <CircularProgress/>
                </div>
              :
                <div></div>
              }
            </div>
        </div>
        <div className='logobackgroundLimit'>
          <img src={logo} alt="logo" className='logobackground'/>
        </div>
        <Dialog
          open={errorAlert}
          onClose={errorAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Contact Us Notification
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please fill in all required fields.
            <br/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={errorAlertClose}>Got it!</Button>
          </DialogActions>
        </Dialog>
      </div>


      <Footer />
      {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
    </div>
  );
};

export default HomePage;
