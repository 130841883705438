// HomePage.js
import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import '../css/aboutus.scss'

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import yusong from '../img/yusong.jpg'
import logo from '../svg/logo.svg';


  
const Aboutus = () => {
  const [info,setInfo]=useState([{
      'name':'Howie Huang',
      'info':[
        "Haowei brings over a decade of experience in structural engineering to AIPD. His expertise spans across various materials for structural designs, including structural steel, cold-formed steel, timber, masonry, and concrete. He has extensive experience in Finite Element Analysis, from structural modelling to microscale material modelling. He also tackled remedial works, even going the extra mile by abseiling high-rises in Sydney to identify facade defects.\n\nHaowei's passion for innovation led him to explore coding during his PhD studies. Recognising the potential of software development, he began working part-time for a software company, developing structural design software. His PhD research involves applying machine learning models to predict the mechanical properties of composites. This experience solidified his belief in the enormous potential of machine learning within civil engineering, ultimately leading to the creation of AIPD.\n\nDespite his busy schedule, Haowei is a dedicated surfer, finding time to catch waves whenever possible."
      ],
      'avatar':'howie',
      'title':'CEO'
    },
    {
      'name':'ElyasAsadi Shamsabadi',
      'info':[
        'Elyas is a postdoctoral researcher specializing in digital construction, with a career dedicated to enhancing productivity and efficiency in the construction industry through advanced technologies. His academic journey includes a PhD focused on machine learning-assisted vision-based defect detection, where he gained profound expertise in both machine learning and its practical applications in infrastructure maintenance. Prior to his doctoral studies, Elyas amassed extensive experience with cement-based materials, deepening his understanding of material properties and behaviors. Over the years, Elyas has made significant contributions to the development of optimal materials by leveraging generative AI and autonomous frameworks. His innovative work in digital construction has been recognized and published in prestigious scientific journals, underscoring his impact on the field.'
      ],
      'avatar':'elyas',
      'title': 'Machine Learning Engineer' 
    },
    {
      'name':'Yu Song',
      'info':[
        'Yu possesses a formidable technical foundation and a fervent dedication to innovation. With three years of hands-on experience as a software engineer, she demonstrates a versatile skill set, showcasing exceptional proficiency in frontend development. \n\n Beyond her professional pursuits, Yu thrives on a multitude of interests, including hiking, surfing, camping, spearfishing, and traveling. Her insatiable curiosity fuels her quest for new adventures, reflecting a vibrant and dynamic personality committed to both professional excellence and personal growth.'
      ],
      'avatar':'yusong',
      'title':'Software Engineer',
    }
  ]);
  const [check,setCheck]=useState(false)
  const [hoverInfo,setHoverInfo]=useState({})
  const [hoverIndex,setHoverIndex]=useState(-1)

  const handlePopoverOpen = (data,index) => {
    setCheck(true);
    setHoverInfo(data);
    setHoverIndex(index)
  };

  const handlePopoverClose = () => {
    setCheck(false);
    setHoverIndex(-1)
  };

  return (
    <div className="">
        <div className="aboutUs">
            <div onMouseEnter={handlePopoverClose}>
            <h1 className="center" >About Us</h1>
            <h5 className="center desctipTeam">The AIPD founding team has immense industry experience in structural engineering and software development. We also possess a deep academic background, having collectively published over two dozen journal papers in top-tier civil engineering journals. Additionally, our expertise extends to the application of machine learning within the civil engineering field, as evidenced by multiple published papers on this very topic.
            </h5>
            </div>
            <div className="cards">
            {info.map((data,index)=>(
                <div className="info" key={index+'info'}>
                <img src={require('../img/'+data.avatar+'.jpg')} onMouseEnter={()=>handlePopoverOpen(data,index)} className={`${hoverIndex==index||hoverIndex==-1?'imgFocus':'hover'}`}/>
                </div>
            ))}
            </div>
            <div className="infoCard">
            
            { hoverIndex==-1?
                <Box>
                <Fade in={!check}>
                    <div className="discrip">
                    <div className="value">
                        <h2 className="center valueText">" Innovation</h2>
                        <h2 className="center valueText"> Collaboration </h2>
                        <h2 className="center valueText"> Integrity "</h2>
                    </div>
                    {/* <div className="detail">
                        <h2 className="">The AIPD founding team has immense industry experience in structural engineering and software development. We also possess a deep academic background, having collectively published over two dozen journal papers in top-tier civil engineering journals. Additionally, our expertise extends to the application of machine learning within the civil engineering field, as evidenced by multiple published papers on this very topic.</h2>
                    </div> */}
                    </div>
                </Fade>
                </Box>
                :
                <Box sx={{ display: 'flex' }}>
                <Fade in={check}>
                    <div>
                    <div className="title">{hoverInfo.name} - {hoverInfo.title}</div>
                    <div className="subTitle">{hoverInfo.info}</div>
                    </div>
                </Fade>
                </Box>
            }
            </div>
        </div>
    </div>
  );
};

export default Aboutus;
