// HomePage.js
import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import '../css/aboutus.scss'
import logo from '../svg/logo.svg';

import About from '../components/aboutUs';

  
const Aboutus = () => {

  return (
    <div className="page">
      <Header />
      <div className="mainBody">
        <About />
        <div className='logobackgroundLimit'>
          <img src={logo} alt="logo" className='logobackground'/>
        </div>
      </div>


      <Footer />
    </div>
  );
};

export default Aboutus;
